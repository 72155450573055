exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-agri-commodity-tsx": () => import("./../../../src/pages/agri-commodity.tsx" /* webpackChunkName: "component---src-pages-agri-commodity-tsx" */),
  "component---src-pages-business-opportunities-tsx": () => import("./../../../src/pages/business-opportunities.tsx" /* webpackChunkName: "component---src-pages-business-opportunities-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-investors-corner-tsx": () => import("./../../../src/pages/investors-corner.tsx" /* webpackChunkName: "component---src-pages-investors-corner-tsx" */),
  "component---src-pages-investors-grivences-tsx": () => import("./../../../src/pages/investors-grivences.tsx" /* webpackChunkName: "component---src-pages-investors-grivences-tsx" */),
  "component---src-pages-management-details-tsx": () => import("./../../../src/pages/management-details.tsx" /* webpackChunkName: "component---src-pages-management-details-tsx" */),
  "component---src-pages-material-events-tsx": () => import("./../../../src/pages/material-events.tsx" /* webpackChunkName: "component---src-pages-material-events-tsx" */),
  "component---src-pages-testimonials-tsx": () => import("./../../../src/pages/testimonials.tsx" /* webpackChunkName: "component---src-pages-testimonials-tsx" */),
  "component---src-pages-the-mrc-way-tsx": () => import("./../../../src/pages/the-mrc-way.tsx" /* webpackChunkName: "component---src-pages-the-mrc-way-tsx" */),
  "component---src-pages-warehousing-tsx": () => import("./../../../src/pages/warehousing.tsx" /* webpackChunkName: "component---src-pages-warehousing-tsx" */),
  "component---src-pages-why-us-tsx": () => import("./../../../src/pages/why-us.tsx" /* webpackChunkName: "component---src-pages-why-us-tsx" */)
}

